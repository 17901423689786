/* eslint-disable no-shadow */
import React, { useEffect, useMemo, useState } from 'react';
import { bool, func, shape, string, oneOf } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from 'react-intl';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get } from 'lodash';
import { parse, format } from 'date-fns';
import ActivityFeed from '../ActivityFeed/ActivityFeed';
import routeConfiguration from '../../routeConfiguration';
import { updateRecentPackages } from '../../util/localStorage';
import {
  getListingImageCaptions,
  getPackageImages,
  getPackage,
  listingStateLinks,
  getCampsites,
  getLodging,
  getLodgingPricesOptions,
} from '../../util/listing';
import { hasPremiumMembership } from '../../util/user';
import {
  LODGING_INFO,
  getStartingPrice,
  getTransactionFromInquiry,
  checkPackageAvailabilty,
  isPackageOnWaitingList,
  getPackageLodgingPrices,
  isEarlyAccessAvailable,
} from '../../util/package';
import { LISTING_STATE_PENDING_APPROVAL, propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
  formatNumber,
} from '../../util/data';
import {
  huntingFaqs,
  outdoorRecFaqs,
  HUNT_ACTIVITY,
  OUTDOOR_RECREATION_ACTIVITY,
} from '../../marketplace-custom-config';
import { parseMoneyObjString } from '../../util/currency';
import { SUMMARY } from '../../util/editListing';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/UI.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import {
  toggleFavoritePackages,
  updateWaitingList,
  followSubject,
  unfollowSubject,
  updateFollowedSubject,
  fetchFollowedSubjects,
} from '../../ducks/user.duck';
import { fetchTransaction } from '../../ducks/Transaction.duck';
import {
  Page,
  CredovaPlugin,
  EarlyAccessBar,
  NamedRedirect,
  NamedLink,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  PackageBadges,
  PackageQuantityBadge,
  PackagePricingPanel,
  PackageAvailabilityModal,
  SectionPropertyVisit,
  SectionFAQ,
  EarlyAccessModal,
} from '../../components';
import { PACKAGE_QUANTITY_BADGE_VARIANTS } from '../../components/PackageQuantityBadge/PackageQuantityBadge';
import { Container } from '../../components/Layout';
import { STEP_PARTY } from '../../components/InquiryWizard/InquiryWizardContext';
import { TopbarContainer } from '..';
import PackageNotFoundPage from '../PackageNotFoundPage/PackageNotFoundPage';
import {
  loadData,
  setInitialValues,
  fetchPackageActivityFeed,
  fetchActivityLogLikes,
} from '../ListingPage/ListingPage.duck';
import SectionImages from '../ListingPage/SectionImages';
import SectionHeading from '../ListingPage/SectionHeading';
import SectionDescriptionMaybe from '../ListingPage/SectionDescriptionMaybe';
import SectionHostMaybe from '../ListingPage/SectionHostMaybe';
import SectionCountdown from './SectionCountdown';
import MobileCheckAvailability from './MobileCheckAvailability';
import SectionHelpfulResources from '../ListingPage/SectionHelpfulResources';
import SectionLandDetails from '../ListingPage/SectionLandDetails';
import SectionLodgingOptions from '../ListingPage/SectionLodgingOptions';

import SectionRules from '../ListingPage/SectionRules';
import SectionMapMaybe from '../ListingPage/SectionMapMaybe';
import ShareButton from '../ListingPage/ShareButton';
import SectionKeyListingInfo from '../ListingPage/SectionKeyListingInfo';
import SectionWhatsIncluded from '../ListingPage/SectionWhatsIncluded';
import SectionAddons from '../ListingPage/SectionAddons';
import SectionSeasonInfo from '../ListingPage/SectionSeasonInfo';
import SEOBreadcrumbs from '../../components/SEOBreadcrumbs/SEOBreadcrumbs';

import css from './PackagePage.css';
import FavoritePackage from '../../components/FavoritePackage/FavoritePackage';
import CredovaModal from '../../components/CredovaPlugin/CredovaModal';
import SectionReviews from '../ListingPage/SectionReviews';

const { UUID } = sdkTypes;

const PageComponent = ({
  children,
  currentUser,
  currentListing,
  currentPackage,
  onToggleFavoritePackage,
  followSubjectMutation,
  unfollowSubjectMutation,
  followedSubjects,
  ...rest
}) => {
  return (
    <Page {...rest} noindex nofollow>
      <LayoutSingleColumn className={css.pageRoot}>
        <LayoutWrapperTopbar>
          <TopbarContainer
            customMobileSecondaryActions={
              <div className={css.mobileSecondaryWrapper}>
                {currentUser && currentListing && (
                  <FavoritePackage
                    currentUser={currentUser}
                    listing={currentListing}
                    package={currentPackage}
                    onFavoritePackage={() =>
                      onToggleFavoritePackage(currentPackage, currentListing)
                    }
                    onUnfavoritePackage={() =>
                      onToggleFavoritePackage(currentPackage, currentListing)
                    }
                    followSubjectMutation={followSubjectMutation}
                    unfollowSubjectMutation={unfollowSubjectMutation}
                    followedSubjects={followedSubjects}
                  />
                )}
                <ShareButton shareDark />
              </div>
            }
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>{children}</LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

export const PackagePageComponent = ({
  currentUser,
  currentUserInit,
  getListing,
  getOwnListing,
  intl,
  onManageDisableScrolling,
  params: rawParams,
  location,
  scrollingDisabled,
  reviews,
  customerReviews,
  reviewsMeta,
  showListingError,
  timeSlots,
  onToggleFavoritePackage,
  onFetchTransaction,
  transaction,
  transactionIdFromInquiry,
  history,
  onUpdateWaitingList,
  isOnWaitingList,
  updatingWaitingListInProgress,
  activityPackageFeedData,
  fetchActivityFeedData,
  followedSubjects,
  fetchFollowedSubjectsData,
  followSubjectMutation,
  unfollowSubjectMutation,
  updateFollowedSubjectMutation,
  fetchTotalLikesData,
  isFetchingReviews,
}) => {
  const [isPackageAvailabilityModalOpen, setIsPackageAvailabilityModalOpen] = useState(false);
  const [isEarlyAccessModalOpen, setIsEarlyAccessModalOpen] = useState(false);
  const [isCredovaModalOpen, setIsCredovaModalOpen] = useState(false);
  const [estimatedTotalPrice, setEstimatedTotalPrice] = useState(0);

  const onContactUser = isOwnListing => {
    const listingId = new UUID(rawParams.id);
    const currentListing = ensureListing(getListing(listingId));
    const currentPackage = getPackage(currentListing, rawParams.packageId);

    const { activity } = currentPackage || {};
    const parsedPackages = {
      ...currentPackage,
      price: parseMoneyObjString(currentPackage.price),
      lodgingPrice: parseMoneyObjString(currentPackage.lodgingPrice),
    };

    if (isOwnListing) {
      // ..
    } else if (transactionIdFromInquiry) {
      history.push(
        createResourceLocatorString('OrderPage', routeConfiguration(), {
          id: transactionIdFromInquiry,
        })
      );
    } else {
      history.push(
        createResourceLocatorString('NewInquiryPage', routeConfiguration(), {
          ...rawParams,
          step: STEP_PARTY.path,
        }),
        {
          activity,
          selectedPackage: parsedPackages,
        }
      );
    }
  };
  const listingId = new UUID(rawParams.id);
  const isPendingApprovalVariant = rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;
  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));
  const lodgingPricesOptions = getLodgingPricesOptions(currentListing, intl);
  const currentPackage = getPackage(currentListing, rawParams.packageId);

  const { optionalLodging, includedLodging } = getPackageLodgingPrices(
    currentPackage,
    lodgingPricesOptions
  );
  const campsites = getCampsites(currentListing);

  const lodging = getLodging(currentListing);
  const includedLodgingMerged = [];
  const optionalLodgingMerged = [];

  const { lodgingPrices = [] } = currentPackage || {};

  lodgingPrices.forEach((item, index) => {
    if (item.option === LODGING_INFO.REQUIRED) {
      includedLodgingMerged.push({ ...item, ...lodgingPricesOptions[index] });
    }
  });

  lodgingPrices.forEach((item, index) => {
    if (item.option === LODGING_INFO.OPTIONAL) {
      optionalLodgingMerged.push({ ...item, ...lodgingPricesOptions[index] });
    }
  });

  const mergedLodgingPrices = [...includedLodgingMerged, ...optionalLodgingMerged];

  useEffect(() => {
    if (transactionIdFromInquiry) {
      onFetchTransaction(new UUID(transactionIdFromInquiry));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionIdFromInquiry]);

  const {
    activity,
    title: packageTitle,
    species_unit: packageGMU,
    description: packageDescription,
    availableDateFrom,
    earlyAccessExpiration,
  } = currentPackage || {};

  const { bookingRangeIsFuture, bookingRangeIsPast, isAvailable } = checkPackageAvailabilty(
    currentPackage
  );

  const date = parse(availableDateFrom);
  const formattedPackageAvailableDate = format(date, 'MMMM D, YYYY');

  const outOfBookingRange = bookingRangeIsFuture || bookingRangeIsPast;

  const userHasPremiumMembership = useMemo(() => hasPremiumMembership(currentUser), [currentUser]);
  const isEarlyAccess = useMemo(
    () => isEarlyAccessAvailable(earlyAccessExpiration) && isAvailable && !outOfBookingRange,
    [earlyAccessExpiration, isAvailable, outOfBookingRange]
  );
  const isBlockedByEarlyAccess = useMemo(() => {
    if (currentUserInit) {
      return !userHasPremiumMembership && isEarlyAccess;
    }

    return false;
  }, [currentUserInit, isEarlyAccess, userHasPremiumMembership]);

  const startingPrice = getStartingPrice({
    ...currentPackage,
    optionalLodging,
    includedLodging,
  });

  const isHunting = activity === HUNT_ACTIVITY;
  const isOutdoorRec = activity === OUTDOOR_RECREATION_ACTIVITY;
  const faqsToRender = isOutdoorRec ? outdoorRecFaqs : huntingFaqs;

  const listingSlug = rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { slug: listingSlug, ...rawParams };
  const listingType = isDraftVariant ? LISTING_PAGE_PARAM_TYPE_DRAFT : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = SUMMARY;

  const isApproved =
    currentListing.id && currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;
  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.
  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage = pendingIsApproved || pendingOtherUsersListing;

  useEffect(() => {
    const packageID = rawParams.packageId;
    const listingID = listingId.uuid;

    if (packageID && listingID) {
      updateRecentPackages(listingID, packageID);
    }
  }, [rawParams.packageId, listingId.uuid]);

  if (shouldShowPublicListingPage) {
    return <NamedRedirect name="ListingPage" params={params} search={location.search} />;
  }

  const {
    description,
    geolocation = null,
    title,
    publicData,
    metadata,
  } = currentListing.attributes;

  const richTitle = (
    <div className={css.packageTitleWrapper}>
      <span className={css.packageTitle}>{packageTitle}</span>

      <span className={css.packageTitleListing}>
        <FormattedMessage id="PackagePage.atTitle" />{' '}
        <NamedLink
          className={css.packageTitleListingLink}
          name="ListingPage"
          params={{
            ...rawParams,
          }}
        >
          {title}
        </NamedLink>
      </span>
    </div>
  );

  if (showListingError) {
    // Other error in fetching listing

    const errorTitle = intl.formatMessage({
      id: 'ListingPage.errorLoadingListingTitle',
    });

    return (
      <PageComponent title={errorTitle} scrollingDisabled={scrollingDisabled}>
        <p className={css.errorText}>
          <FormattedMessage id="ListingPage.errorLoadingListingMessage" />
        </p>
      </PageComponent>
    );
  }

  if (!currentListing.id) {
    // Still loading the listing

    const loadingTitle = intl.formatMessage({
      id: 'ListingPage.loadingListingTitle',
    });

    return (
      <PageComponent title={loadingTitle} scrollingDisabled={scrollingDisabled}>
        <p className={css.loadingText}>
          <FormattedMessage id="ListingPage.loadingListingMessage" />
        </p>
      </PageComponent>
    );
  }

  if (!currentPackage) {
    // Redirect to PackageNotFoundPage if package doesn't exist

    return <PackageNotFoundPage />;
  }

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable && currentListing.author.id.uuid === currentUser.id.uuid;
  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const packagesImages = getPackageImages(currentListing, currentPackage?.id);

  const headerImages = packagesImages.length ? packagesImages : currentListing.images;
  const listingImageCaptions = getListingImageCaptions(currentListing);

  const getSchemaImages = variantName =>
    (headerImages || [])
      .map(image => {
        const { variants } = image.attributes;
        const variant = variants ? variants[variantName] : null;

        // deprecated
        // for backwards compatibility only
        const { sizes } = image.attributes;
        const size = sizes ? sizes.find(i => i.name === variantName) : null;

        return variant || size;
      })
      .filter(variant => variant != null);

  const activityData = get(publicData, `activities.${activity}`, {});
  const landDetails = get(activityData, 'landDetails', null);

  const nearestTown = get(publicData, 'nearestTown.selectedPlace.address', null);
  const acreage = get(publicData, 'location.acreage', 0);
  const acres = formatNumber(acreage);
  const propertyVisit = get(publicData, 'propertyVisit', null);

  const usState = get(currentListing, 'attributes.publicData.location.usState', null);
  const usCounty = get(currentListing, 'attributes.publicData.location.usCounty', null);
  const facebookImages = getSchemaImages('facebook');
  const twitterImages = getSchemaImages('twitter');
  const schemaImages = JSON.stringify(facebookImages.map(img => img.url));
  const schemaTitle = intl.formatMessage(
    { id: 'PackagePage.schemaTitle' },
    {
      packageTitle,
      title,
      usState,
    }
  );
  const { licenseLink, regulationsLink } = listingStateLinks(currentListing);

  const BLOCK_REGULATIONS_FOR_STATES = [
    'Alabama',
    'Wisconsin',
    'Illinois',
    'Mississippi',
    'Georgia',
  ];
  const shouldShowRegulationsLink = usState
    ? !BLOCK_REGULATIONS_FOR_STATES.includes(usState)
    : true;

  const landownerName = get(currentListing, 'author.attributes.profile.displayName', 'Landowner');

  const renderAvailabilityBadges = isShortVersion => {
    let badge;

    if (!isAvailable) {
      badge = {
        variant: PACKAGE_QUANTITY_BADGE_VARIANTS.DANGER,
        text: isShortVersion ? (
          <FormattedMessage
            id="PackagePricingPanel.soldOutSimple"
            values={{ date: formattedPackageAvailableDate }}
          />
        ) : (
          <FormattedMessage id="PackagePage.soldOutInquiry" values={{ landowner: landownerName }} />
        ),
      };
    } else if (outOfBookingRange) {
      badge = {
        variant: PACKAGE_QUANTITY_BADGE_VARIANTS.WARNING,
        text: isShortVersion ? (
          <FormattedMessage
            id="PackagePricingPanel.outOfRange"
            values={{ date: formattedPackageAvailableDate }}
          />
        ) : (
          <FormattedMessage
            id="PackagePage.outOfBookingRange"
            values={{ date: formattedPackageAvailableDate, landowner: landownerName }}
          />
        ),
      };
    }

    if (!badge) {
      return null;
    }

    return (
      <div className={css.badgeWrapper}>
        <PackageQuantityBadge variant={badge.variant} size="large" text={badge.text} />
      </div>
    );
  };
  const requiredLodgingPrices = mergedLodgingPrices.filter(
    item => item.option === LODGING_INFO.REQUIRED
  );
  const optionalLodgingPrices = mergedLodgingPrices.filter(
    item => item.option === LODGING_INFO.OPTIONAL
  );

  const seoBreadcrumbs =
    usState && usCounty
      ? [
          <a href="/" target="_blank" rel="noreferrer">
            <FormattedMessage id="ListingPage.BreadcrumbsHome" />{' '}
          </a>,
          <a href="/us" target="_blank" rel="noreferrer">
            US{' '}
          </a>,
          <a href={`/us/${usState.toLowerCase()}`} target="_blank" rel="noreferrer">
            {usState}{' '}
          </a>,
          <a
            href={`/us/${usState.toLowerCase()}/${usCounty.toLowerCase().replaceAll(' ', '-')}`}
            target="_blank"
            rel="noreferrer"
          >
            {usCounty}{' '}
          </a>,
        ]
      : [];

  const handlePriceChange = price => {
    setEstimatedTotalPrice(price);
  };

  return (
    <PageComponent
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      contentType="website"
      description={packageDescription}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'ItemPage',
        description,
        name: schemaTitle,
        image: schemaImages,
      }}
      currentUser={currentUser}
      currentListing={currentListing}
      currentPackage={currentPackage}
      onToggleFavoritePackage={onToggleFavoritePackage}
      followSubjectMutation={followSubjectMutation}
      unfollowSubjectMutation={unfollowSubjectMutation}
      followedSubjects={followedSubjects}
    >
      <PackageAvailabilityModal
        id="package-pricing-availability-modal"
        isOpen={isPackageAvailabilityModalOpen}
        onClose={() => setIsPackageAvailabilityModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        isSoldOut={!isAvailable}
        isOutOfBookingRange={outOfBookingRange}
        packageAvailableDate={formattedPackageAvailableDate}
        landownerName={landownerName}
        onNotify={() => {
          onUpdateWaitingList(currentPackage.id, currentListing.id.uuid);
        }}
        isFetching={updatingWaitingListInProgress}
        isOnWaitingList={isOnWaitingList}
      />

      <div className={css.topSectionContainer}>
        <div>
          <EarlyAccessBar
            isEarlyAccess={isEarlyAccess}
            userHasPremiumMembership={userHasPremiumMembership}
            expirationTimestamp={currentPackage?.earlyAccessExpiration}
          />
        </div>

        <Container className={css.sectionImagesContainer}>
          <SectionImages
            title={title}
            listing={currentListing}
            listingImages={headerImages}
            listingImageCaptions={listingImageCaptions}
            isOwnListing={isOwnListing}
            editParams={{
              id: listingId.uuid,
              slug: listingSlug,
              type: listingType,
              tab: listingTab,
            }}
            onManageDisableScrolling={onManageDisableScrolling}
          />
          <div className={css.listingActions}>
            <div className={css.listingActionsContent}>
              <div className={css.listingInfoContainer}>
                <SectionKeyListingInfo
                  nearestTown={nearestTown}
                  acres={acres}
                  gameUnit={packageGMU}
                  currentActivity={activity}
                />
                <SectionHeading
                  richTitle={richTitle}
                  listing={currentListing}
                  metaData={metadata}
                  reviews={customerReviews}
                  reviewsMeta={reviewsMeta}
                  isFetchingReviews={isFetchingReviews}
                />
              </div>

              <div className={css.mobileBadges}>
                <div className={css.divider} />

                <div className={css.formFieldHeading}>
                  <FormattedMessage id="PackageCard.includes" />
                </div>
                <PackageBadges packageInfo={currentPackage} />
              </div>
            </div>
            <div className={css.shareContainer} data-testid="share-container">
              <ShareButton className={css.share} buttonClassName={css.shareButton} />
              <FavoritePackage
                currentUser={currentUser}
                listing={currentListing}
                package={currentPackage}
                onUnfavoritePackage={() => onToggleFavoritePackage(currentPackage, currentListing)}
                onFavoritePackage={() => onToggleFavoritePackage(currentPackage, currentListing)}
                followSubjectMutation={followSubjectMutation}
                unfollowSubjectMutation={unfollowSubjectMutation}
                followedSubjects={followedSubjects}
                inlineButton
              />
            </div>
          </div>
        </Container>
      </div>
      <Container className={css.contentContainer}>
        <div className={css.mainContent}>
          {(!isAvailable || outOfBookingRange) && (
            <div className={css.availabilityBadgesWrapper}>{renderAvailabilityBadges(true)}</div>
          )}

          <SectionDescriptionMaybe
            description={packageDescription}
            publicData={publicData}
            nearestTown={nearestTown}
          />

          <div className={css.mobileCredovaWrapper}>
            <CredovaPlugin price={estimatedTotalPrice} onModalOpen={setIsCredovaModalOpen} />
          </div>

          <SectionWhatsIncluded
            publicData={publicData}
            currentPackage={currentPackage}
            activityData={activityData}
            onManageDisableScrolling={onManageDisableScrolling}
            includedLodging={includedLodging}
          />
          <SectionLodgingOptions
            campsites={campsites}
            lodging={lodging}
            lodgingPricesOptions={requiredLodgingPrices}
          />
          <SectionAddons
            currentListing={currentListing}
            params={params}
            lodgingPricesOptions={optionalLodgingPrices}
          />
          {packageGMU && (
            <SectionSeasonInfo
              publicData={publicData}
              currentPackage={currentPackage}
              currentListing={currentListing}
            />
          )}
          {isHunting && (
            <SectionHelpfulResources
              licenseLink={licenseLink}
              regulationsLink={regulationsLink}
              showRegulationsLink={shouldShowRegulationsLink}
            />
          )}

          <div className={css.activityFeedContainer}>
            <ActivityFeed
              metaData={metadata}
              reviews={reviews}
              propertyVisit={propertyVisit}
              currentPackage={currentPackage}
              listing={currentListing}
              rootClassName={css.activityFeedRoot}
              isListing={false}
              activityPackageFeedData={activityPackageFeedData}
              fetchActivityFeedData={fetchActivityFeedData}
              fetchFollowedSubjectsData={fetchFollowedSubjectsData}
              followedSubjects={followedSubjects}
              followSubjectMutation={followSubjectMutation}
              unfollowSubjectMutation={unfollowSubjectMutation}
              updateFollowedSubjectMutation={updateFollowedSubjectMutation}
              currentUser={currentUser}
              fetchTotalLikesData={fetchTotalLikesData}
            />
          </div>

          {landDetails && <SectionLandDetails landDetails={landDetails} categories={activity} />}
          <hr className={css.totalDivider} />
          <SectionHostMaybe
            listing={currentListing}
            onContactUser={() => onContactUser(isOwnListing)}
            currentUser={currentUser}
            mobileHost
            isEarlyAccess={isEarlyAccess}
            isContactDisabled={!isAvailable || outOfBookingRange}
            hideExtraSpace
          />

          <div className={css.hostBadgeWrapper}>{renderAvailabilityBadges()}</div>

          <div className={css.sectionCountdown}>
            <SectionCountdown
              isEarlyAccess={isEarlyAccess}
              userHasPremiumMembership={userHasPremiumMembership}
              earlyAccessExpiration={earlyAccessExpiration}
            />
          </div>

          <hr className={css.activityTotalDivider} />

          {propertyVisit && (
            <SectionPropertyVisit
              propertyVisit={propertyVisit}
              rootClassName={css.propertyVisitRoot}
            />
          )}
          <SectionFAQ faqs={faqsToRender} />
          <SectionRules publicData={publicData} intl={intl} />
          <SectionReviews
            customerReviews={customerReviews}
            reviews={reviews}
            reviewsMeta={reviewsMeta}
            isFetchingReviews={isFetchingReviews}
          />
          <SectionHostMaybe
            listing={currentListing}
            onContactUser={() => onContactUser(isOwnListing)}
            currentUser={currentUser}
            fullProfile
            showRate
            isEarlyAccess={isEarlyAccess}
            isContactDisabled={!isAvailable || outOfBookingRange}
          />
          <div className={css.hostBadgeWrapper}>{renderAvailabilityBadges()}</div>

          <div className={css.sectionCountdown}>
            <SectionCountdown
              isEarlyAccess={isEarlyAccess}
              userHasPremiumMembership={userHasPremiumMembership}
              earlyAccessExpiration={earlyAccessExpiration}
            />
          </div>

          <SectionMapMaybe
            geolocation={geolocation}
            publicData={publicData}
            listingId={currentListing.id}
          />
          {usState && usCounty && <SEOBreadcrumbs breadcrumbs={seoBreadcrumbs} />}
        </div>
        <div className={css.secondaryContent}>
          <div>
            <PackagePricingPanel
              currentUserInit={currentUserInit}
              packageInfo={currentPackage}
              includedLodging={includedLodging}
              optionalLodging={optionalLodging}
              listing={currentListing}
              timeSlots={timeSlots}
              startingPrice={startingPrice}
              currentUser={currentUser}
              sticky
              transaction={transaction}
              availabilityPackageBadges={renderAvailabilityBadges(true)}
              isAvailable={isAvailable}
              outOfBookingRange={outOfBookingRange}
              bookingRangeIsFuture={bookingRangeIsFuture}
              isBlockedByEarlyAccess={isBlockedByEarlyAccess}
              formattedPackageAvailableDate={formattedPackageAvailableDate}
              onManageDisableScrolling={onManageDisableScrolling}
              isOnWaitingList={isOnWaitingList}
              setIsPackageAvailabilityModalOpen={setIsPackageAvailabilityModalOpen}
              setIsEarlyAccessModalOpen={setIsEarlyAccessModalOpen}
              setIsCredovaModalOpen={setIsCredovaModalOpen}
              onPriceChange={handlePriceChange}
              showCredova
            />
          </div>

          <CredovaModal
            isOpen={isCredovaModalOpen}
            onCloseModal={() => setIsCredovaModalOpen(false)}
          />

          <EarlyAccessModal
            isOpen={isEarlyAccessModalOpen}
            onClose={() => {
              setIsEarlyAccessModalOpen(false);
            }}
          />

          <div>
            <MobileCheckAvailability
              currentUserInit={currentUserInit}
              isAvailable={isAvailable}
              outOfBookingRange={outOfBookingRange}
              startingPrice={startingPrice}
              currentTransaction={transaction}
              isOnWaitingList={isOnWaitingList}
              setIsPackageAvailabilityModalOpen={setIsPackageAvailabilityModalOpen}
              rawParams={rawParams}
              isBlockedByEarlyAccess={isBlockedByEarlyAccess}
              earlyAccessExpiration={earlyAccessExpiration}
              setIsEarlyAccessModalOpen={setIsEarlyAccessModalOpen}
              setIsCredovaModalOpen={setIsCredovaModalOpen}
              estimatedTotalPrice={estimatedTotalPrice}
            />
          </div>
        </div>
      </Container>
    </PageComponent>
  );
};

PackagePageComponent.defaultProps = {
  currentUser: null,
  showListingError: null,
};

PackagePageComponent.propTypes = {
  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,

  params: shape({
    id: string.isRequired,
    slug: string,
    variant: oneOf([LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT]),
  }).isRequired,

  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  showListingError: propTypes.error,
};

const mapStateToProps = (state, ownProps) => {
  const { isAuthenticated } = state.Auth;
  const {
    showListingError,
    reviews,
    customerReviews,
    reviewsMeta,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    fetchingReviewsDataInProgress,
  } = state.ListingPage;
  const { currentUser, updatingWaitingListInProgress, currentUserInit } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);

    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const isOnWaitingList = isPackageOnWaitingList(currentUser, ownProps?.params?.packageId);

  let transactionIdFromInquiry = getTransactionFromInquiry(
    currentUser,
    ownProps?.params?.packageId
  );

  const { transactionRef } = state.Transaction;
  const transactions = getMarketplaceEntities(state, transactionRef ? [transactionRef] : []);

  // Make sure that we have transaction in pending inquiries
  const transaction =
    transactions.length > 0
      ? transactions.find(tx => tx.id.uuid === transactionIdFromInquiry) || null
      : null;

  if (!transaction) {
    transactionIdFromInquiry = null;
  }

  return {
    isAuthenticated,
    currentUserInit,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    showListingError,
    reviews,
    customerReviews,
    reviewsMeta,
    fetchReviewsError,
    timeSlots,
    fetchTimeSlotsError,
    transaction: transactionIdFromInquiry ? transaction : null,
    transactionIdFromInquiry,
    isOnWaitingList,
    updatingWaitingListInProgress,
    activityPackageFeedData: state.ListingPage.activityPackageFeedData,
    followedSubjects: state.user.followedSubjects,
    reviewsByIds: state.ListingPage.reviewsByIds,
    isFetchingReviews: fetchingReviewsDataInProgress,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onToggleFavoritePackage: (pkg, listing) => dispatch(toggleFavoritePackages(pkg, listing)),
  onFetchTransaction: txId => dispatch(fetchTransaction(txId)),
  onUpdateWaitingList: (packageId, listingId) => dispatch(updateWaitingList(packageId, listingId)),
  fetchActivityFeedData: (packageId, limit, offset) =>
    dispatch(fetchPackageActivityFeed(packageId, limit, offset)),
  fetchTotalLikesData: updateId => dispatch(fetchActivityLogLikes(updateId)),
  fetchFollowedSubjectsData: userId => dispatch(fetchFollowedSubjects(userId)),
  followSubjectMutation: (userId, subjectId, notifyBy) =>
    dispatch(followSubject(userId, subjectId, notifyBy)),
  unfollowSubjectMutation: (id, userId) => dispatch(unfollowSubject(id, userId)),
  updateFollowedSubjectMutation: (id, notifyBy) => dispatch(updateFollowedSubject(id, notifyBy)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const PackagePage = compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  injectIntl
)(PackagePageComponent);

PackagePage.setInitialValues = initialValues => setInitialValues(initialValues);
PackagePage.loadData = loadData;

export default PackagePage;
